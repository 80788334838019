import compose from "recompose/compose";
import { graphql } from "react-apollo";
import { withRouter } from "react-router";

import config from "config/website";

const EnhanceHome = ({ CmsPageQuery }) =>
  compose(
    withRouter,
    graphql(CmsPageQuery, {
      name: "homepage",
      options: {
        variables: {
          identifiers: [config.homepage_identifier],
        },
      },
      props: ({ homepage }) => ({
        homePageSeo: !homepage.loading && homepage.cmsPageList[0],
      }),
    })
  );

export default EnhanceHome;

import Home from "theme/pages/Home";

export default Home;

/**
 * To enable cache control for home page (or any static page for that matters) define a static property `cacheControlDefinition` on the Component itself. This property should have `enabled` set to true and proper `maxAge` and `staleWhileRevalidate` values
 * Also make sure the statics of the component is hoisted all the way up so that this property is available when imported by other modules
 */

const ONE_MINUTE = 60;
Home.cacheControlDefinition = {
  sMaxAge: ONE_MINUTE,
  staleWhileRevalidate: ONE_MINUTE * 60 * 6,
};

module.exports = {
  defaultBgColor: "FFFFFF",
  presets: {
    swatch: { width: 25, height: 25, bgColors: [] },
    thumbnail: { width: 50, height: 50, bgColors: [] },
    small: { width: 136, height: 167, bgColors: [] },
    galleryPreview: { width: 136, height: 136, bgColors: [] },
    medium: { width: 474, height: 474, bgColors: [] },
    large: { width: 1100, height: 1100, bgColors: [] },
    newsletter: { width: 1280, height: 242, bgColors: [] },
    carousel: { width: 1280, height: 600, bgColors: [] },
    pushBlock: { width: 284, height: 353, bgColors: [] },
    pushBlockWide: { width: 568, height: 353, bgColors: [] },
    cartPreview: { width: 50, height: 50, bgColors: [] },
    wishlistPreview: { width: 50, height: 50, bgColors: [] },
    zoomable: { width: 1100, height: 1100, bgColors: [], sizes: [2] },
  },
};

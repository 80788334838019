import React from "react";
import compose from "recompose/compose";
import { Helmet } from "react-helmet-async";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import HomeSeo from "theme/modules/Home/HomeSeo";
import Newsletter from "theme/modules/Newsletter";
import Stack from "theme/components/atoms/Layout/Stack";
import { SuccessAlert } from "theme/components/molecules/Alert";
import MegaHeading from "theme/components/atoms/Typography/Heading/Mega";
import Icon from "theme/components/atoms/Icon";
import Carousel from "theme/components/organisms/Carousel";
import FeatureList, { Feature } from "theme/components/organisms/FeatureList";
import PushBlock from "theme/components/organisms/PushBlock/PushBlock";
import Grid from "theme/components/templates/Grid/Grid";
import Cell from "theme/components/templates/Grid/Cell";
import { H2 } from "theme/components/atoms/Typography/Heading";
import Link from "theme/components/atoms/Typography/Link";
import Section from "theme/components/organisms/Section";
import FeaturedProducts from "theme/modules/ProductList/FeaturedProducts";
import EnhanceHome from "./EnhanceHome";
import HomePageSeoQuery from "./HomePageSeoQuery.gql";

const HomeReassurance = () => {
  return (
    <FeatureList>
      <Feature
        icon={<Icon appearance="block" icon="cube" title="" />}
        title={"Free Shipping"}
        description="On purchases over $199"
      />
      <Feature
        icon={<Icon appearance="block" icon="smile" title="" />}
        title={"99% satisfied customers"}
        description="Our clients' opinions speak for themselves"
      />
      <Feature
        icon={<Icon appearance="block" icon="lock" title="" />}
        title={"Originality Guaranteed"}
        description="30 days warranty for each product from our store"
      />
    </FeatureList>
  );
};

const HomeCarousel = () => {
  const slides = [
    {
      key: "1",
      title: (
        <MegaHeading as="h1">First Sale of the summer collection</MegaHeading>
      ),
      to: "/venia-bottoms.html",
      linkTitle: "Shop now",
      imageSrc: "/images/resized/carousel-background.png",
      priority: true,
    },
    {
      key: "2",
      title: (
        <MegaHeading as="h2">Second Sale of the summer collection</MegaHeading>
      ),
      to: "/venia-tops/venia-blouses.html",
      linkTitle: "Shop now",
      imageSrc: "/images/resized/carousel-background.png",
    },
    {
      key: "3",
      title: (
        <MegaHeading as="h2">Third Sale of the summer collection</MegaHeading>
      ),
      to: "/venia-accessories.html",
      linkTitle: "Shop now",
      imageSrc: "/images/resized/carousel-background.png",
    },
    {
      key: "4",
      title: (
        <MegaHeading as="h2">Fourth Sale of the summer collection</MegaHeading>
      ),
      to: "/venia-tops/venia-sweaters.html",
      linkTitle: "Shop now",
      imageSrc: "/images/resized/carousel-background.png",
    },
  ];

  return <Carousel slides={slides} content={<HomeReassurance />} />;
};

const Home = (props) => (
  <>
    {props.homePageSeo && (
      <Helmet>
        <title>Front-Commerce Magento2 Demo</title>
        {props.homePageSeo.meta_keywords && (
          <meta name="keywords" content={props.homePageSeo.meta_keywords} />
        )}
        {props.homePageSeo.meta_description && (
          <meta
            name="description"
            content={props.homePageSeo.meta_description}
          />
        )}
      </Helmet>
    )}
    <HomeSeo />

    <div className="container">
      {props.location.state && props.location.state.successMessage && (
        <SuccessAlert>{props.location.state.successMessage}</SuccessAlert>
      )}
    </div>
    <Stack size="8">
      <Stack size="4" mobileSize="8">
        <HomeCarousel />
        <div className="container">
          <Grid>
            <Cell size="desktop-2">
              <PushBlock
                imageSrc="/images/resized/hoodie.png"
                format="pushBlockWide"
              >
                <H2>New arrivals are in!</H2>
                <Link buttonAppearance="default" to="/venia-dresses.html">
                  Show collection
                </Link>
              </PushBlock>
            </Cell>
            <Cell size="1">
              <PushBlock
                imageSrc="/images/resized/black-t-shirt.png"
                format="pushBlock"
              >
                <H2>Basic t-shirts $29,99</H2>
                <Link
                  buttonAppearance="default"
                  to="/venia-tops/venia-blouses.html"
                >
                  More details
                </Link>
              </PushBlock>
            </Cell>
            <Cell size="1">
              <PushBlock
                imageSrc="/images/resized/sale-push.png"
                format="pushBlock"
              >
                <H2>Sale this summer</H2>
                <Link buttonAppearance="default" to="/new-products3.html">
                  View all
                </Link>
              </PushBlock>
            </Cell>
          </Grid>
        </div>
      </Stack>
      <Section
        title={<H2>Suggested for you</H2>}
        actions={
          <Link to="/venia-accessories.html" buttonAppearance="default">
            Show more
          </Link>
        }
      >
        <FeaturedProducts categoryId="5" />
      </Section>
      <Section title={<H2>Why should you choose us?</H2>}>
        <div className="container">
          <FeatureList>
            <Feature
              appearance="big"
              icon={<Icon size="big" appearance="block" icon="cube" title="" />}
              title={"Free Shipping"}
              description="All purchases over $199 are eligible for free shipping via USPS First Class Mail."
            />
            <Feature
              appearance="big-primary"
              icon={<Icon size="big" appearance="block" icon="cash" title="" />}
              title={"Easy Payments"}
              description="All payments are processed instantly over a secure payment protocol."
            />
            <Feature
              appearance="big"
              icon={<Icon size="big" appearance="block" icon="lock" title="" />}
              title={"Money-Back Guarantee"}
              description="If an item arrived damaged or you've changed your mind, you can send it back for a full refund."
            />
            <Feature
              appearance="big"
              icon={<Icon size="big" appearance="block" icon="star" title="" />}
              title={"Finest Quality"}
              description="Designed to last, each of our products has been crafted with the finest materials."
            />
          </FeatureList>
        </div>
      </Section>
      <Section title={<H2>Products in today</H2>}>
        <FeaturedProducts categoryId="8" />
      </Section>
      <div className="container">
        <Newsletter />
      </div>
    </Stack>
  </>
);

export default compose(
  EnhanceHome({ CmsPageQuery: HomePageSeoQuery }),
  withTrackPage("Home")
)(Home);
